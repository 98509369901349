import React from "react";
import "../../assets/home/homeAboutUs.scss";
import "../../assets/home/homeOurService.scss";
import { StaticQuery, graphql, Link } from "gatsby";
import Buttonv3 from "../buttons/buttonv3";

export default function HomeOurService() {
  return (
    <StaticQuery
      query={graphql`
        {
          allStrapiServices {
            nodes {
              title
              titleHindi
              slug
              description
              icon {
                localFile {
                  publicURL
                }
              }
            }
            totalCount
          }
        }
      `}
      render={(data) => (
        <div className="HomeAboutUs" style={{ backgroundColor: "ECF8F9" }}>
          <div className="section-bg" style={{ backgroundColor: "#FFE6D2" }} />
          <div className="gridView-Sub" style={{ marginBottom: 10 }}>
            <div className="">
              <div className="ti">Our Services</div>
              <h2 className="homeAboutTitle">
                We build Pest control solutions that solve B2B/B2C challenges
              </h2>
              <Buttonv3 title={"Request Quote  →"} toLink="/contact-us" />
            </div>

            {data.allStrapiServices.nodes.map((v, k) => (
              <div key={k} className="cardService">
                <div className="tii">{v.title}</div>
                <p className="">{v.description}</p>
                <p className="readMore">
                  <Link
                    to={`services/` + v.slug}
                    style={{ textDecoration: "none" }}
                  >
                    Read More →
                  </Link>
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    ></StaticQuery>
  );
}
