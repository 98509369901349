import React from "react";
import "../../assets/home/homeAboutUs.scss";
import "../../assets/home/howItWorks.scss";
import { StaticQuery, graphql } from "gatsby";

export default function HomeHowItWorks() {
  return (<StaticQuery
        query={graphql`{
                strapiHomepage {
                  howItWorks {
                    mainTitle
                    sectionCard {
                      title
                      body
                      icon {
                    localFile {
                      childImageSharp {
                        resize {
                          src
                        }
                      }
                    }
                  }
                  }
                }
              }
            }
        `}
        render={({strapiHomepage:{ howItWorks }}) => (<div className="HomeAboutUs">
      <div className="onlyText">
        <div className="sub1">
          <div className="ti">How it works</div>
          <h2 className="homeAboutTitle">
            {howItWorks.mainTitle}
          </h2>
          <div className="sub22">

          </div>
        </div>
      </div>

      <div className="gridView-Sub">
          {howItWorks.sectionCard.map((v,k)=>
            <div className={`howItWork-sub ${k===0&&'n1'}`}>
              <div>
                <img src={v.icon[0].localFile.childImageSharp.resize.src} width={35} alt="" />
              </div>
              <div>
                <p className="howItWork-title">{v.title}</p>
                {v.body}
              </div>
            </div>
          )}
        {/* <div className="howItWork-sub n1">
          <div>
            <img src="https://cdn-icons-png.flaticon.com/512/2451/2451548.png" width={35} alt="" />
          </div>
          <div>
            <p className="howItWork-title">Select a City</p>
            We aim to attain the greatest satisfaction for our clients and be one of the prominent.
          </div>
        </div>
        <div className="howItWork-sub">
        <div>
            <img src="https://cdn-icons-png.flaticon.com/512/535/535239.png" width={35} alt="" />
          </div>
          <div>
          <p className="howItWork-title">Choose your location</p>
          We aim to attain the greatest satisfaction for our clients and be one of the prominent.
          </div>
        </div>
        <div className="howItWork-sub">
        <div>
            <img src="https://cdn-icons-png.flaticon.com/512/269/269947.png" width={35} alt="" />
          </div>
          <div>
         <p className="howItWork-title">Residential/Commercial</p>
         We aim to attain the greatest satisfaction for our clients and be one of the prominent.
         </div>
        </div>
        <div className="howItWork-sub">
        <div>
            <img src="https://cdn-icons-png.flaticon.com/512/2782/2782996.png" width={35} alt="" />
          </div>
          <div>
          <p className="howItWork-title">Choose Area</p>
          We aim to attain the greatest satisfaction for our clients and be one of the prominent.
          </div>
        </div>
        <div className="howItWork-sub">
        <div>
            <img src="https://cdn-icons-png.flaticon.com/512/66/66163.png" width={35} alt="" />
          </div>
          <div>
          <p className="howItWork-title">Time Slot</p>
          We aim to attain the greatest satisfaction for our clients and be one of the prominent.
          </div>
        </div>
        <div className="howItWork-sub">
        <div>
            <img src="https://cdn-icons-png.flaticon.com/512/7634/7634614.png" width={35} alt="" />
          </div>
          <div>
          <p className="howItWork-title">Level</p>
          We aim to attain the greatest satisfaction for our clients and be one of the prominent.
          </div>
        </div> */}
      </div>

    </div>)}></StaticQuery>
  );
}
