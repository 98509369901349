import React from "react";
import "../../assets/home/treatmentNeeded.scss";
import { StaticQuery, graphql, navigate } from "gatsby";

export default function TreatmentNeededComponent({ cb }) {
  return (
    <div className="treatmentNeeded">
      <h3 style={{color:'#FFB800',marginRight:20}}>Treatment needed?</h3>
      <StaticQuery
        query={graphql`
          {
            allStrapiServices {
              nodes {
                title
                slug
              }
              totalCount
            }
          }
        `}
        render={(data) => (
          <div className="button_parent">
            {console.log("TEST", data)}
            {data.allStrapiServices.nodes.map((v, k) => (
              <p
                key={k}
                onClick={() => {
                  navigate("/booking/" + v.slug);
                  cb(true);
                }}
                className="clicker"
              >
                {v.title.replace('Control','').replace('Management','')}
              </p>
            ))}
          </div>
        )}
      ></StaticQuery>
    </div>
  );
}


 {/* <div className="button_parent">
            {console.log("TEST", data)}
            {data.allStrapiServices.nodes.map((v, k) => (
              <p
                key={k}
                onClick={() => {
                  navigate("/booking/" + v.slug);
                  cb(true);
                }}
                className="clicker"
              >
                {v.title}
              </p>
            ))}
          </div> */}