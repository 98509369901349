import React, { useState } from "react";
import Layout from "../components/Layout";
import { StaticQuery, graphql } from "gatsby";
import TreatmentNeededComponent from "../components/home/treatmentNeeded";
import HomeAboutUs from "../components/home/HomeAboutUs";
import HomeOurExpertise from "../components/home/HomeOurExpertise";
import HomeOurService from "../components/home/HomeOurService";
import RatingComponent from "../components/home/HomeComponent/RatingComponent";
import HomeHowItWorks from "../components/home/HomeHowItWorks";
import HomeOurCustomer from "../components/home/HomeOurCustomer";
import HomeReadBlogNews from "../components/home/HomeReadBlogNews";

const Page = () => {
  const [loader, setLoader] = useState(false);
  return (
    <StaticQuery
      query={Query}
      render={(data) =>
        loader ? (
          <div className="loaderBod">
            <div className="loader2"></div>
          </div>
        ) : (
          <Layout cb={setLoader}>
            <div className="home">
              <div
                className="hero"
                style={{
                  background:
                    "  linear-gradient(to right, rgb(19, 19, 19) 0, rgb(19, 19, 19) 30%, rgba(0, 0, 0, 0) 100%),url(" +
                    data.allStrapiHomepage.nodes[0].background.localFile
                      .childImageSharp.fluid.srcWebp +
                    ")",
                  backgroundRepeat: "repeat, no-repeat",
                  backgroundSize: "auto, cover",
                }}
              >
                <meta name="yandex-verification" content="ea98e5c10c734ffa" />
                <div className="wrapper_max">
                  <h1>
                    {data.allStrapiHomepage.nodes[0].titleprefix}
                    <span className="red">
                      {data.allStrapiHomepage.nodes[0].redTitle}
                    </span>
                    <span>{data.allStrapiHomepage.nodes[0].suffixTitles}</span>
                  </h1>
                  <h3>{data.allStrapiHomepage.nodes[0].SubHeading}</h3>
                  <p>{data.allStrapiHomepage.nodes[0].Description}</p>

                  <RatingComponent />

                  <TreatmentNeededComponent cb={setLoader} />
                </div>
              </div>

              <HomeAboutUs />
              <HomeOurExpertise />
              <HomeOurService />
              <HomeHowItWorks />
              <HomeOurCustomer />
              <HomeReadBlogNews />
            </div>
          </Layout>
        )
      }
    />
  );
};

const Query = graphql`
  {
    allStrapiHomepage {
      nodes {
        redTitle
        suffixTitles
        titleprefix
        SubHeading
        Description
        background {
          localFile {
            childImageSharp {
              fluid {
                srcWebp
              }
            }
          }
        }
      }
    }
    allStrapiServices {
      nodes {
        title
        slug
      }
      totalCount
    }
  }
`;

export default Page;
