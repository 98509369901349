import React from "react";
import "../../assets/home/homeAboutUs.scss";
import CountUp from 'react-countup';
import { StaticQuery, graphql, navigate } from "gatsby";

export default function HomeAboutUs() {

  const Sub2 = (props) => (
        <div className={`sub2 ${props.className}`}>
          <div className="sub21">
            <h3>{props.title}</h3>
          </div>
          <div className="sub22">
            {props.description}
          </div>
        </div>
  );

  const Sub2Title = "Pestgogo The Science First Company";
  const Sub2Description= "We are pest control experts with the 'Science First' approach. We practice preventive pest control in environment friendly & sustainable ways to build a healthier & safer living & workplace."

  return (
<StaticQuery
        query={graphql`{
    allStrapiHomepage {
      nodes {
        tick {
          ticker
          title
        }
        homeSection {
          description
          mainTitle
          subTitle
          image1 {
            localFile {
              childrenImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          image2 {
            localFile {
              childrenImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          image3 {
            localFile {
              childrenImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }`}
        render={(data) => (
    <div className="HomeAboutUs">
      <div className="onlyText">
        <div className="sub1">
          <div className="ti">About Us</div>
          <h2 className="homeAboutTitle">
            {data.allStrapiHomepage.nodes[0].homeSection.mainTitle}
          </h2>
        </div>
        
        <Sub2 className="hide-mobile" title={data.allStrapiHomepage.nodes[0].homeSection.subTitle} description={data.allStrapiHomepage.nodes[0].homeSection.description}/>

      </div>

      <div className="homeaboutImg">
        <img
          src={data.allStrapiHomepage.nodes[0].homeSection.image1.localFile.childrenImageSharp[0].fluid.srcWebp}
          alt="pestgogo team"
        />
        <img
        src={data.allStrapiHomepage.nodes[0].homeSection.image2.localFile.childrenImageSharp[0].fluid.srcWebp}
          alt="pestgogo chemicals used"
        />
        <img
        src={data.allStrapiHomepage.nodes[0].homeSection.image3.localFile.childrenImageSharp[0].fluid.srcWebp}
          alt="pestgogo team"
        />
      </div>

      <div className="countTicker">
        {data.allStrapiHomepage.nodes[0].tick.map((v,k)=><div>
          <p className="tick"><CountUp enableScrollSpy={true} end={v.ticker}/>+</p>
          <div className='seperator'/>
          <p className='tickName'>{v.title}</p>
        </div>)}
      </div>
      <Sub2 className="show-mobile" title={Sub2Title} description={Sub2Description}/>
    </div>
    )}
      ></StaticQuery>
  )
}
