import React from "react";
import "../../assets/home/homeAboutUs.scss";
import { StaticQuery, graphql } from "gatsby";

export default function HomeOurCustomer() {
  return (
    <StaticQuery
      query={graphql`
        {
          strapiHomepage {
            testimony {
              title
              description
              testimonySection {
                comment
                profile {
                  localFile {
                    url
                  }
                  name
                }
                name
                designation
              }
            }
          }
        }
      `}
      render={({ strapiHomepage: { testimony } }) => (
        <div className="HomeAboutUs" style={{ backgroundColor: "ECF8F9" }}>
          <div className="onlyText">
            <div className="sub1">
              <div className="ti"></div>
              <h2 className="homeAboutTitle">{testimony.title}</h2>
              <div className="sub22">{testimony.description}</div>
            </div>

            <div className="sub2 n0">
              <div style={{ margin: 20, padding: 20 }}>
                <p>{testimony.testimonySection[0].comment}</p>
                <div className="quotes-base">{`"`}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    ></StaticQuery>
  );
}
