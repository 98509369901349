import React from "react";
import "../../assets/home/homeAboutUs.scss";
import "../../assets/home/ReadOurLatestBlog.scss";
import { StaticQuery, graphql, Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";

export default function HomeReadBlogNews() {
  return (
    <StaticQuery
      query={graphql`
        {
          allStrapiArticles(limit: 2) {
            edges {
              node {
                id
                title
                slug
                image {
                  url
                  localFile {
                    childrenImageSharp {
                      fluid(maxWidth: 300) {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allStrapiArticles }) => (
        <div className="HomeAboutUs">
          <div className="section-bg" style={{ backgroundColor: "#FFE6D2" }} />
          <div className="onlyText">
            <div className="sub1">
              <h2 className="homeAboutTitle">Read our latest blogs & news</h2>
              <div className="sub22">
                PESTGOGO is the India’s largest pest control company, offering
                the broadest, most advanced range of pest control solutions and
                services in the industry.
              </div>
            </div>
          </div>

          <div className="gridView2" style={{ marginTop: 30 }}>
            {allStrapiArticles.edges.map((v, k) => (
              <div className="flexRow" key={k}>
                {/* <div className="flexPadd" style={{backgroundImage:}}> */}
                {/* <img
                    src={v.node.image.localFile.childrenImageSharp[0].fluid.src}
                    width={200}
                    alt=""
                  /> */}
                {/* </div> */}
                <BackgroundImage
                  className="flexPadd"
                  backgroundColor={`#0080FF`}
                  fluid={v.node.image.localFile.childrenImageSharp[0].fluid}
                ></BackgroundImage>
                <div className="flexPadd2">
                  Jan 19 2022
                  <h2>{v.node.title}</h2>
                  <Link to={"/blog/" + v.node.slug}>
                    <p>Read More</p>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    ></StaticQuery>
  );
}
