import React from "react";
import { StaticQuery, graphql } from "gatsby";
import "../../assets/home/homeAboutUs.scss";

export default function HomeOurExpertise() {
  return (
    <StaticQuery
      query={graphql`
        {
          allStrapiHomepage {
            nodes {
              ourExpertise {
                mainTitle
                description
                sectionCard {
                  body
                  title
                  icon {
                    url
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className="HomeAboutUs">
          <div className="section-bg" style={{ backgroundColor: "#ECF8F9" }} />
          <div className="onlyText">
            <div className="sub1">
              <div className="ti">Our expertise</div>
              <h2 className="homeAboutTitle">
                {data.allStrapiHomepage.nodes[0].ourExpertise.mainTitle}
              </h2>
              <div className="sub22">
                {data.allStrapiHomepage.nodes[0].ourExpertise.description}
                <br />
                <br />
              </div>
            </div>

            <div className="sub2">
              {data.allStrapiHomepage.nodes[0].ourExpertise.sectionCard.map(
                (v, k) => (
                  <div
                    key={k}
                    className={`expertise-div + ${
                      k ===
                      data.allStrapiHomepage.nodes[0].ourExpertise.sectionCard
                        .length -
                        1
                        ? "bottom-seperator"
                        : ""
                    }`}
                  >
                    <img src={v.icon[0].url} width={50} height={50} />
                    <div>
                      <b>{v.title}</b>
                      <p>{v.body}</p>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>

          <div className="homeaboutImg">
            {/* <img
          src="https://pestgogo-production.s3.ap-south-1.amazonaws.com/Whats_App_Image_2022_09_28_at_1_58_d4cb5cb462.png"
          alt="pestgogo team"
        />
        <img
          src="https://pestgogo-production.s3.ap-south-1.amazonaws.com/Whats_App_Image_2022_09_28_at_12_35_d91026abed.png"
          alt="pestgogo chemicals used"
        />
        <img
          src="https://pestgogo-production.s3.ap-south-1.amazonaws.com/Whats_App_Image_2022_09_28_at_1_57_fa5521618f.png"
          alt="pestgogo team"
        /> */}
          </div>
        </div>
      )}
    ></StaticQuery>
  );
}
