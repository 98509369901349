import React from "react";

import { FaStar } from "@react-icons/all-files/fa/FaStar";
import { FaStarHalf } from "@react-icons/all-files/fa/FaStarHalf";
import { AiOutlineRight } from "@react-icons/all-files/ai/AiOutlineRight";
import Buttonv3 from "../../buttons/buttonv3";

export default function RatingComponent() {
  return (
    <div className="RequestQuotesBody">
      <Buttonv3 title={"Request Quote  →"} toLink="/contact-us" />
      <div className="review">
        <h3 style={{ color: "white", padding: 0, margin: 0 }}>4.8/5</h3>
        <div style={{ padding: 0 }}>
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStarHalf />
        </div>
        <p className="reviewText">938 Google reviews</p>
      </div>
    </div>
  );
}
